<template>
  <div>
    <a-row :gutter="[8,8]">
      <a-col :span="12">
        <label for="SelectDateRange">Выбери период отгрузки</label>
        <a-range-picker  style="width: 50%"  id="SelectDateRange" v-model:value="Period" @change="SelectDatePeriod" :allowClear="false"/>
        <div style="margin: 15px 0">
          <a-button style="margin-right: 10px" danger @click="onResetFormFilter"><span style="margin-right: 5px"><i class="fa-light fa-filter-circle-xmark"></i></span>Сбросить фильтры</a-button>
          <a-button  type="primary"  @click="onApplyFormFilter">
            <span style="margin-right: 5px"><i class="fa-light fa-check"></i></span> Применить фильтры
          </a-button>
        </div>
      </a-col>
      <a-col :span="12">
        <a-button style="width: 250px" danger block @click="formExtCostVisible=!formExtCostVisible"> Внести дополнительные расходы </a-button>
      </a-col>
    </a-row>
  </div>
  <a-row >
    <a-col :span="12">


    </a-col>
    <a-col :span="12">

    </a-col>
  </a-row>

  <hr>
  <a-table  :columns="column0" :data-source="GetDatos.total" size="small"  style=" padding: 0 10px;  " class="table-level-0"  >


    <template  #expandedRowRender="{record, index, indent, expanded}"   >
      <a-table  class="table-level-1" :columns="column1"  :data-source="GetDatos.PerDay"   :pagination="false" size="small"  style="" >

        <template  #expandedRowRender="{record}">
          <a-table class="table-level-2" :columns="column2"  :data-source="GetDatos.Operation[record.workday]" :expandRowByClick="true"  style=""
                   :pagination="false" size="small" >
            <template #bodyCell="{ column, record }">

              <template v-if="column.key === 'sale_entity_name'">
                 <span v-if="(record.sale_entity_name === 'Дополнительные статьи доходов')">
                   <a-popover  trigger="hover">
                     <template #content>
                       <p>{{ record.comment }}</p>
                     </template>
                     {{ record.sale_entity_name }}
                     <a-button  title="Удалить" size="small" @click="onDeleteExtOperaion(1, record.sale_entity_id)"> <delete-outlined /> </a-button>
                   </a-popover>
                 </span>
                <span v-else>
                  <span v-if="record.sale_entity_name !== null">
                    {{ record.sale_entity_name }}
                     <a :href="'https://pro.bitrix24.kz/crm/deal/details/'+ record.sale_entity_id + '/'" target="_blank" > <link-outlined /></a>
                  </span>
                </span>
              </template>

              <template v-if="column.key === 'income_name'">
                <span v-if="record.p_deal_id > 0" >
                  Производственный заказ #{{ record.p_deal_id }}
                  <a :href="'https://pro.bitrix24.kz/crm/deal/details/'+ record.p_deal_id + '/'" target="_blank" > <link-outlined /></a>
                </span>
                <div v-else>
                  <a-popover v-if="(record.income_name === 'Дополнительные статьи расходов' ) " trigger="hover">
                    <template #content>
                      <p>{{ record.comment }}</p>
                    </template>
                    {{ record.income_name }}
                    <a-button  title="Удалить" size="small" @click="onDeleteExtOperaion(2, record.income_entity_id)"> <delete-outlined /> </a-button>
                  </a-popover>

                  <span v-else>{{ record.income_name }}</span>
                </div>
              </template>


            </template>

            <template #expandedRowRender="{record}"  >

              <a-table class="table-level-3"  :columns="column3"  :data-source="GetDatos.Products[record.keys]" style=""

                       :pagination="false" size="small">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'KT_product'">
                    <a-popover v-if="record.comment.length > 0 " trigger="hover">
                      <template #content>
                        <p>{{ record.comment }}</p>
                      </template>
                      {{ record.KT_product }}
                    </a-popover>
                    <span v-else>{{ record.KT_product }}</span>
                  </template>
                </template>
                <template #expandedRowRender="{record}"  >
                  <a-table :columns="column4" @expand="getInfo(record)"  :data-source="GetDatos.Items[record.keys]"

                           :pagination="false" size="small">

                  </a-table>
                </template>
              </a-table>
            </template>
          </a-table>
        </template>
      </a-table>
    </template>
  </a-table>

  <div>
    <a-modal v-model:open="formExtCostVisible" title="Дополнительные расходы" :footer="null">
      <a-form
          layout="vertical"
          :model="FormExtendedCost"
          @finish="OnExtendedCost"
      >
        <a-form-item :name="['wd']" label="Выберите дату"  :rules="[{ required: true,  message: 'Обязательное поле'  }]" >
          <a-date-picker
              v-model:value="FormExtendedCost.wd"
              type="date"
              placeholder="Выберите дату"
              style="width: 100%"

          />
        </a-form-item>
        <a-form-item
            name="direction"  label='Выбери направление' has-feedback  >
          <a-select v-model:value="FormExtendedCost.direction" @select="onSelect">
            <a-select-option selected value="0">Не выбрано</a-select-option>
            <a-select-option value="1">Доходы</a-select-option>
            <a-select-option value="2">Расходы</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :name="['name']" :label="'Наименование статьи '+title "  :rules="[{ required: true,  message: 'Обязательное поле'  }]" >
          <a-input v-model:value="FormExtendedCost.name" placeholder="Укажите наименование статьи расходов"  />
        </a-form-item>

        <a-form-item :name="['total']" :label="'Сумма '+title"  :rules="[{ required: true,  message: 'Обязательное поле'  }]" >
          <a-input type="number" :step="0.01" v-model:value="FormExtendedCost.total" placeholder="Укажите сумму расходов "  />
        </a-form-item>
        <a-form-item :name="['comment']" label="Дополнительный комментарий"  >
          <a-textarea v-model:value="FormExtendedCost.comment" placeholder="Укажите комментарий  " :rows="4"  />
        </a-form-item>
        <a-form-item>
          <a-button  type="primary"  html-type="submit"  > <span style="margin-right: 5px"><i class="fa-light fa-check"></i></span> Сохранить
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";
import { LinkOutlined , EditOutlined, DeleteOutlined} from '@ant-design/icons-vue';

//import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
// import moment from 'moment';
// import 'moment/dist/locale/ru'

export default {
  name: 'HelloWorld',
  components :{
    LinkOutlined,  EditOutlined, DeleteOutlined
  },
  computed : {
    ...mapGetters(['GetDatos', 'GetOperationData', 'GetItemData'])
  },
  data(){
    return {
      title :'',
      FormExtendedCost : {
        wd: '',
        name : '',
        total : '',
        comment : '',
        workdate : '',
        keys : '',
        direction : 'Не выбрано',
      },


      formExtCostVisible : false,
      Period : null,
      DayStart : null,
      DayEnd : null,
      RowKey : 10000000,
      column0 : [
        { title: '',dataIndex: 'workday', key: 'workday', width : '25%', },
        { title: 'Сумма прихода', dataIndex: 'DT_Total', key: 'DT_Total', width : '25%', align : 'right' },
        { title: 'Сумма затрат', dataIndex: 'KT_Total', key: 'KT_Total' , width : '42%',align : 'right'},
        { title: 'Сальдо ', dataIndex: 'Saldo', key: 'Saldo' , width : '8%', align : 'right'},

      ],
      column1 : [
        { title: 'Дата',dataIndex: 'workday', key: 'workday',  },
        { title: 'Сумма прихода', dataIndex: 'DT_Total', key: 'DT_Total', align : 'right' },
        { title: 'Сумма затрат', dataIndex: 'KT_Total', key: 'KT_Total' , width : '42.5%', align : 'right'},
        { title: 'Сальдо ', dataIndex: 'Saldo', key: 'Saldo' ,width : '8%', align : 'right'},
      ],
      column2 : [
        { title: 'Вид Дохода',dataIndex: 'sale_entity_name', key: 'sale_entity_name',},
        { title: 'Вид оплаты', dataIndex: 'payment', key: 'payment', },
        { title: 'Город', dataIndex: 'city', key: 'city' },
        { title: 'Сумма ', dataIndex: 'sale_total', key: 'sale_total', align : 'right' },
        { title: 'Вид расхода ', dataIndex: 'income_name', key: 'income_name' ,  width :'36%',},
        { title: 'Сумма ', dataIndex: 'income_total', key: 'income_total', width : '8%', align : 'right'},
        { title: 'Сальдо ', dataIndex: 'Saldo', key: 'Saldo' ,width :'8%', align : 'right'},

      ],
      column3 : [
        {title: 'Товар', dataIndex : 'DT_product', key : "DT_product" },
        {title: 'Кол-во', dataIndex : 'DT_quantity', key : "DT_quantity" , align : 'center' , },
        {title: 'Сумма', dataIndex : 'DT_total', key : "DT_total",  align : 'right'  ,},
        {title: 'Товар', dataIndex : 'KT_product', key : "KT_product" ,  width : '30%'},
        {title: 'Кол-во', dataIndex : 'KT_quantity', key : "KT_quantity" ,  align : 'center',width : '8%'  },
        {title: 'Сумма', dataIndex : 'KT_total', key : "KT_total",   align : 'right' ,width : '8%' },
        {title: '' ,width : '8%'},

      ],
      column4 :[
        {title: '' , width : 500},
        {title: 'Товар', dataIndex : 'prod_name', key : "prod_name", width : '32%' },
        {title: 'Кол-во', dataIndex : 'quantity', key : "quantity" ,  align : 'center' ,width :'8%' },
        {title: 'Сумма', dataIndex : 'total', key : "total",   align : 'right' , width :'8%'},
        {title: '' ,width : '8%'},
      ]

    }
  },
  methods : {
    ...mapActions(['GET_DATA', 'GET_OPERATION', "GET_ITEMS", 'SET_DATA']),

    GetOperationsData(xx){
     // console.log(xx)
      this.GET_OPERATION({workday : xx});
    },
    GetItems(xx){
      this.GET_OPERATION({keys : xx});
    },

    getInfo( info){
      console.log( info)
    },
    SelectDatePeriod(){
      this.DayStart = JSON.stringify(this.Period[0]).substring(1,11)
      this.DayEnd = JSON.stringify(this.Period[1]).substring(1,11)
    },
    onResetFormFilter(){

    },
    onApplyFormFilter(){
      this.GET_DATA({dayStart : this.DayStart, dayEnd: this.DayEnd})
    },
    OnExtendedCost(){
      if(!Number(this.FormExtendedCost.direction)){
        alert('Выбери направление ! ')
        return false;
      }
      if(Number(this.FormExtendedCost.total)){

        this.FormExtendedCost.workdate = new Date(this.FormExtendedCost.wd).toJSON().substring(0,10)
        this.SET_DATA(this.FormExtendedCost);
        //Обновляем данные с текущим фильтром
        setTimeout(() => { this.onApplyFormFilter(); }, 1500);
        //закрываем форму
        this.formExtCostVisible = false;
        this.FormExtendedCost = {}

      }else{
        alert('Сумма расходов должна быть числом')
      }

    },
    onDeleteExtOperaion(direction, key){
      this.SET_DATA({ mode : 'del', direction : direction , key: key })
      //Обновляем данные с текущим фильтром
      setTimeout(() => { this.onApplyFormFilter(); }, 1500);
    },
    onSelect(value){
      if(value == 1){
        this.title = 'доходов'
      }else{
        this.title = 'расходов'
      }

      console.log(value);
    }


  },

}

</script>

<style>
.table-level-0  .ant-table-container  {
  background-color: #FEFED2 ;
}

.table-level-0 .ant-table-cell {
  background-color: #ECF6D7;
  font-weight: bold;
  font-style: italic;
}

.table-level-0 .ant-table-thead .ant-table-cell{
  background-color: #CAE6C9 ;
}
/*
.table-level-1  .ant-table-container  {
  background-color: #ABCECA;
}

.table-level-1 .ant-table-cell {
  background-color: #BBC4D4;
  font-weight: bold;
}

.table-level-1 .ant-table-thead .ant-table-cell{
  background-color:  #736D66 ;
}

.table-level-2  .ant-table-container  {
  background-color:  transparent;
}

.table-level-2 .ant-table-cell {
  background-color: #FFF2E3;

}

.table-level-2 .ant-table-thead .ant-table-cell{
  background-color: #F1E5D6 ;
}

.table-level-3  .ant-table-container  {
  background-color:transparent;
}

.table-level-3 .ant-table-cell {
  background-color: transparent;

}

.table-level-3 .ant-table-thead .ant-table-cell{
  background-color: transparent ;
}
*/








.table-level-2 .ant-table-cell {
  background-color: transparent;
}
.table-level-3 .ant-table-cell {
  background-color: transparent;
}
.table-level-3{
  border: 2px solid brown;
}


</style>