import { createApp } from 'vue'
import App from './App.vue'

import store from './store'

//import "../assets/fonts/awesome/css/all.min.css"

//import vSelect from "vue-select";



// import "vue-select/dist/vue-select.css";
import 'ant-design-vue/dist/reset.css'
import { Button } from "ant-design-vue";
import { Form } from "ant-design-vue";
import { Input } from "ant-design-vue";
import { Select } from "ant-design-vue";
import { Table } from "ant-design-vue";
import { Row } from "ant-design-vue";
import { Col } from "ant-design-vue";
// import { Tabs } from "ant-design-vue";
import { Modal } from "ant-design-vue";
// import { Switch } from "ant-design-vue";
import { Popconfirm } from 'ant-design-vue';
import { DatePicker } from 'ant-design-vue';
// import { Drawer } from 'ant-design-vue';
// import { Tag } from 'ant-design-vue';
import { Alert } from 'ant-design-vue';
// import { Checkbox } from 'ant-design-vue';
// import { Steps } from 'ant-design-vue';
 import { Popover } from 'ant-design-vue';


const app = createApp(App)
app.use(Button);
app.use(Row);
app.use(Col);
app.use(Form);
app.use(Input);
app.use(Select);
app.use(Table);
// app.use(Tabs);
app.use(Modal);
// app.use(Switch);
app.use(Popconfirm);
app.use(DatePicker);
// app.use(Drawer);
// app.use(Tag);
app.use(Alert);
// app.use(Checkbox);
// app.use(Steps);
 app.use(Popover);

app.use(store);

// app.component("v-select", vSelect);





app.mount('#app')