import { createStore } from 'vuex'
import axios from "axios";

//export const url = '  '
export default createStore({
  state: {
    Datos : [],
    Operations : [],
    Items : []
  },
  getters: {
    GetDatos(state){
      return state.Datos
    },
    GetOperationsData(state){
      return state.Operations
    },
    GetItemsData(state){
      return state.Items
    }
  },
  mutations: {
    UpdateData(state, data){
      state.Datos = data;
    },
    UpdateOperationsData(state, data){
      state.Operations = data
    },
    UpdateItemsDate(state, data){
      state.Items = data
    }
  },
  actions: {
    GET_DATA(ctx, data){
      axios("https://bitrix.pro-buket.kz/bitrix/report/report_api.php",
          {
            method: 'POST',
            headers : {
              "Content-Type" : "application/json",
            },
            params:{
              data : data
            }
          })
          .then((result) => {
            ctx.commit('UpdateData', result.data)
          } )
          .catch((error) =>{
            console.log('Ошибка : ' + error);
          })
    },
    GET_OPERATION(ctx, data){
      axios("https://bitrix.pro-buket.kz/bitrix/report/report_api.php", {
        method: 'POST',
        headers : {
          "Content-Type" : "application/json",

        },
        params:{
          oper : data
        }
      })
          .then((result) => {

            ctx.commit('UpdateOperationsData', result.data);
          })
          .catch((error) =>{
            console.log('Ошибка : ' + error);
          })
    } ,
    GET_ITEMS(ctx,data){
      axios("https://bitrix.pro-buket.kz/bitrix/report/report_api.php", {
        method: 'POST',
        headers : {
          "Content-Type" : "application/json",
        },
        params:{
          item : data
        }
      })
          .then((result) => {

            ctx.commit('UpdateItemsDate', result.data);
          })
          .catch((error) =>{
            console.log('Ошибка : ' + error);
          })
    },
    SET_DATA(ctx, data){
      console.log(data)
      axios("https://bitrix.pro-buket.kz/bitrix/report/report_api.php", {
        method: 'POST',
        headers : {
          "Content-Type" : "application/json",
        },
        params:{
          setdata : data
        }
      })
          .catch((error) =>{
            console.log('Ошибка : ' + error);
          })
    }
  },
  modules: {
  }
})